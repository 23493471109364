.map  {
  width: 100%;
  height: calc(100vh - 56px);
}

@media (min-width: 0px) and (orientation: landscape) {
  .map  {
    width: 100%;
    height: calc(100vh - 48px);
  }
}

@media (min-width: 600px) {
  .map  {
    width: 100%;
    height: calc(100vh - 64px);
  }
}